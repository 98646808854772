import {Alert, Collapse} from '@mui/material'
import React, {useEffect} from 'react'
import {UserAlertsProps} from './interfaces'
import {IconButton} from '@mui/material'
import {Close} from '@mui/icons-material'
const UserAlerts: React.FC<UserAlertsProps> = (props: UserAlertsProps) => {
  const [userCreatedSuccessfully, setUserCreatedSuccessfully] = React.useState<boolean>(false)
  // Create a state dictionary to hold alert for each error message
  const [alerts, setAlerts] = React.useState<{[key: string]: boolean}>({})

  useEffect(() => {
    if (props.createUser.result && props.createUser.result.createdUser?.id) {
      if (props.createUser.result.createdUser && props.createUser.result.createdUser.id > 0) {
        setUserCreatedSuccessfully(true)
        setAlerts({...alerts, 'success': true})
      }
      if (props.createUser.result.canEdit.success) {
        setAlerts({...alerts, 'canEdit': !props.createUser.result.canEdit.success})
      }
      if (props.createUser.result.canRead.success) {
        setAlerts({...alerts, 'canRead': !props.createUser.result.canRead.success})
      }
      if (props.createUser.result.upn.success) {
        setAlerts({...alerts, 'upn': !props.createUser.result.upn.success})
      }
      if (props.createUser.result.mail.success) {
        setAlerts({...alerts, 'mail': !props.createUser.result.mail.success})
      }
      if (props.createUser.result.errorMessage) {
        setAlerts({...alerts, 'result': props.createUser.result.errorMessage?.length > 0})
      }
    }
    if (props.createUser.errorMessage) {
      setAlerts({...alerts, 'errorMessage': props.createUser.errorMessage?.length > 0})
    }
  }, [props.createUser])
  if (props.createUser.result) {
    return (
      <div>
        {props.createUser.result.errorMessage && (
          <Collapse in={alerts['result']}>
            <Alert
              severity='error'
              sx={{'maxWidth': '400px'}}
              action={
                <IconButton
                  aria-label='close'
                  color='inherit'
                  size='small'
                  onClick={() => {
                    setAlerts((alerts) => ({...alerts, 'result': false}))
                  }}>
                  <Close fontSize='inherit' />
                </IconButton>
              }>
              {props.createUser.result.errorMessage}
            </Alert>
          </Collapse>
        )}
        {userCreatedSuccessfully && (
          <Collapse in={alerts['success']}>
            <Alert
              severity='success'
              sx={{'maxWidth': '400px'}}
              action={
                <IconButton
                  aria-label='close'
                  color='inherit'
                  size='small'
                  onClick={() => {
                    setAlerts((alerts) => ({...alerts, 'success': false}))
                  }}>
                  <Close fontSize='inherit' />
                </IconButton>
              }>{`User ${props.createUser.result.createdUser?.mail} created successfully!`}</Alert>
          </Collapse>
        )}
        {!props.createUser.result.canEdit.success && (
          <Collapse in={alerts['canEdit']}>
            <Alert
              severity='error'
              sx={{'maxWidth': '400px'}}
              action={
                <IconButton
                  aria-label='close'
                  color='inherit'
                  size='small'
                  onClick={() => {
                    setAlerts((alerts) => ({...alerts, 'canEdit': false}))
                  }}>
                  <Close fontSize='inherit' />
                </IconButton>
              }>
              Kunde ej spara skrivrättigheter för användaren.
            </Alert>
          </Collapse>
        )}
        {!props.createUser.result.canRead.success && (
          <Collapse in={alerts['canRead']}>
            <Alert
              severity='error'
              sx={{'maxWidth': '400px'}}
              action={
                <IconButton
                  aria-label='close'
                  color='inherit'
                  size='small'
                  onClick={() => {
                    setAlerts((alerts) => ({...alerts, 'canRead': false}))
                  }}>
                  <Close fontSize='inherit' />
                </IconButton>
              }>
              Kunde ej spara läsrättigheter för användaren.
            </Alert>
          </Collapse>
        )}
        {!props.createUser.result.upn.success && (
          <Collapse in={alerts['upn']}>
            <Alert
              severity='error'
              sx={{'maxWidth': '400px'}}
              action={
                <IconButton
                  aria-label='close'
                  color='inherit'
                  size='small'
                  onClick={() => {
                    setAlerts((alerts) => ({...alerts, 'upn': false}))
                  }}>
                  <Close fontSize='inherit' />
                </IconButton>
              }>
              Kunde ej sätta UPN för användaren.
            </Alert>
          </Collapse>
        )}
        {!props.createUser.result.mail.success && (
          <Collapse in={alerts['mail']}>
            <Alert
              severity='error'
              sx={{'maxWidth': '400px'}}
              action={
                <IconButton
                  aria-label='close'
                  color='inherit'
                  size='small'
                  onClick={() => {
                    setAlerts((alerts) => ({...alerts, 'mail': false}))
                  }}>
                  <Close fontSize='inherit' />
                </IconButton>
              }>
              Mailutskick med inbjudan misslyckades.
            </Alert>
          </Collapse>
        )}
      </div>
    )
  } else if (props.createUser.errorMessage.length > 0) {
    return (
      <div>
        <Collapse in={alerts['errorMessage']}>
          <Alert
            sx={{'maxWidth': '400px'}}
            severity='error'
            action={
              <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={() => {
                  setAlerts((alerts) => ({...alerts, 'errorMessage': false}))
                }}>
                <Close fontSize='inherit' />
              </IconButton>
            }>
            {props.createUser.errorMessage.indexOf('409') !== -1 ? 'Användarnamnet är upptaget.' : props.createUser.errorMessage}
          </Alert>
        </Collapse>
      </div>
    )
  } else {
    return <div></div>
  }
}
export default UserAlerts
