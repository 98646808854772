import {Dispatch} from 'redux'
import treeService from '../../services/treeService'
import {ActionTypes} from './treeTypes'
import {hasEditPermissions, isNullOrWhiteSpace} from '../../shared/utils/utilities'
import userService from '../../services/userService'
import counterService from '../../services/counterService'
import {ADDRESS, COUNTER, ESTATE, MEASUREPOINT, OWNER, PARSE_ERROR} from '../../shared/utils/constants'
import {isNumeric} from '../../shared/utils/utilities'
import {ICounterPathDto} from '../counter/interfaces'
import {IBreadCrumbPath} from './treeInterfaces'
import measurePointService from '../../services/measurepointService'
import addressService from '../../services/addressService'
import estateService from '../../services/estateService'
import ownerService from '../../services/ownerService'

export const getTree = () => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.GET_TREE_LOADING})
  let result = await treeService.getTree()

  if (!hasEditPermissions()) {
    const userOwners = await userService.getCurrentUserOwners()
    result = treeService.filterNodesByOwner(result, userOwners)
  }

  if (result) {
    return dispatch({type: ActionTypes.GET_TREE_SUCCESS, payload: result})
  } else {
    return dispatch({type: ActionTypes.GET_TREE_FAILED, payload: result})
  }
}

export const getTreeFiltered = (keyword: string, meterTypeId?: number | undefined, office?: string | undefined) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.GET_TREE_LOADING})
  let result = null
  if (!isNullOrWhiteSpace(keyword) && meterTypeId && meterTypeId > 0) {
    result = await treeService.getTreeFilteredByMeterTypeAndKeyword(meterTypeId, keyword)
  } else if (isNullOrWhiteSpace(keyword) && meterTypeId && meterTypeId > 0) {
    result = await treeService.getTreeFilteredByMeterType(meterTypeId)
  } else if (!isNullOrWhiteSpace(keyword)) {
    result = await treeService.getTreeFilteredByKeyword(keyword)
  } else {
    result = await treeService.getTree()
  }

  if (!hasEditPermissions()) {
    const userOwners = await userService.getCurrentUserOwners()
    result = treeService.filterNodesByOwner(result, userOwners)
  }

  if (result) {
    return dispatch({type: ActionTypes.GET_TREE_SUCCESS, payload: result})
  } else {
    return dispatch({type: ActionTypes.GET_TREE_FAILED, payload: result})
  }
}

export const setSelectedMeterType = (id: number) => async (dispatch: Dispatch) => {
  return dispatch({type: ActionTypes.SET_SELECTED_METER_TYPE, payload: id})
}

export const loadUnfilteredTree = (nodes: any[]) => async (dispatch: Dispatch) => {
  return dispatch({type: ActionTypes.SET_FILTERED_NODES, payload: nodes})
}

export const setSelectedOffice = (office: string) => async (dispatch: Dispatch) => {
  return dispatch({type: ActionTypes.SET_SELECTED_OFFICE, payload: office})
}

export const setKeywordFilter = (keyword: string) => async (dispatch: Dispatch) => {
  return dispatch({type: ActionTypes.SET_KEYWORD, payload: keyword})
}

export const applyFilters = (nodes: any[], office: string, meterTypeId: number, keyword: string) => async (dispatch: Dispatch) => {
  const auth = localStorage.getItem('auth')
  if (!isNullOrWhiteSpace(office)) {
    nodes = treeService.filterNodesByOffice(nodes, office)
  }
  if (meterTypeId > 0) {
    nodes = treeService.applyMeterTypeFilter(nodes, meterTypeId)
  }
  if (!isNullOrWhiteSpace(keyword) && keyword.length > 3) {
    if(keyword.startsWith("id:")) {
      nodes = treeService.applyIdStringFilter(nodes, keyword)
    }
    else {
      nodes = treeService.applyKeywordFilter(nodes, keyword)
    }
  }
  return dispatch({type: ActionTypes.SET_FILTERED_NODES, payload: nodes})
}
export const getPath = (id: string, entityType: string) => async (dispatch: Dispatch) => {  
  if (!isNumeric(id)) return dispatch({type: ActionTypes.FETCH_PATH_FAILED, payload: PARSE_ERROR})
  const parsedId = parseInt(id)
  let path: IBreadCrumbPath[] = []
  let entityTypeToUpper = entityType.toUpperCase()
  try {
    switch (entityTypeToUpper) {
      case COUNTER:
        path = await counterService.getBreadCrumbPath(parsedId)
        break
      case MEASUREPOINT:
        path = await measurePointService.getBreadCrumbPath(parsedId)
        break
      case ADDRESS:
        path = await addressService.getBreadCrumbPath(parsedId)
        break
      case ESTATE:
        path = await estateService.getBreadCrumbPath(parsedId)
        break
      case OWNER:
        path = await ownerService.getBreadCrumbPath(parsedId)
        break
      default:
        break
    }
    return dispatch({type: ActionTypes.FETCH_PATH_SUCCESS, payload: path})
  } catch (error) {
    return dispatch({type: ActionTypes.FETCH_PATH_FAILED, payload: error})
  }
}
export const setExpandedNodes = (expandedNodes: string[]) => async (dispatch: Dispatch) => {
  return dispatch({type: ActionTypes.SET_EXPANDED_NODES, payload: expandedNodes})
}
