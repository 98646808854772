import { useState, ChangeEvent, useContext, useEffect } from 'react';
import { Card, Grid, ListItem, List, ListItemText, Divider, Switch, Button, TextField, IconButton, Alert, Collapse } from '@mui/material';
import { ThemeContext } from '../../shared/theme/ThemeProvider';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { selfResetCurrentUserActions } from '../../redux/user/actions';
import { Close } from '@mui/icons-material';
import { PASSWORD_UPDATED } from '../../shared/utils/constants';
import { RootState } from '../../redux/rootReducer';
import { graphToYear } from '../../shared/utils/utilities';

interface SettingsPasswordReset {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

function NotificationsTab() {
  const { themeName, setThemeName } = useContext(ThemeContext);
  const { register, handleSubmit, control } = useForm<SettingsPasswordReset>();
  const [alertOpen, setAlertOpen] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const dispatch = useDispatch();
  const [state, setState] = useState({
    hideGraphs: false,
    showVolume: false,
    graphFromYear: new Date().getFullYear() - 2,
    graphToYear: new Date().getFullYear(),
  });

  const userPasswordReset = useSelector((state: RootState) => state.user.userPasswordReset);

  useEffect(() => {
    const { result, errorMessage } = userPasswordReset;

    if (result?.responseMessage === PASSWORD_UPDATED) {
      setAlertOpen(true);
      setErrorAlertOpen(false);
    } else if (result?.responseMessage === 'ERROR' || errorMessage?.length > 0) {
      setAlertOpen(false);
      setErrorAlertOpen(true);
    } else {
      setAlertOpen(false);
      setErrorAlertOpen(false);
    }
  }, [userPasswordReset]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {        
    const settings = { ...state, [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value};
    setState(settings);
    const storedJson = localStorage.getItem('localSettings');
    if (!storedJson || JSON.stringify(settings) !== storedJson) {
      localStorage.setItem('localSettings', JSON.stringify(settings));
    }
  };

  const onSubmit: SubmitHandler<SettingsPasswordReset> = async (data) =>
    dispatch(selfResetCurrentUserActions(data.oldPassword, data.newPassword, data.confirmPassword));

  useEffect(() => {
    const storedJson = localStorage.getItem('localSettings');
    if (storedJson) {
      setState(JSON.parse(storedJson));
    }
  }, []);

  const changeTheme = () => {
    themeName === 'DarkTheme' ? setThemeName('DefaultsTheme') : setThemeName('DarkTheme');
  };

  return (
    <Grid container direction="row" spacing={3}>
      <Grid item xs={12}>
        {userPasswordReset.result?.responseMessage === PASSWORD_UPDATED && (
          <Collapse in={alertOpen} sx={{ mb: 2 }}>
            <Alert
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setAlertOpen(false);
                  }}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2, maxWidth: '1230px' }}
            >
              Ditt lösenord har uppdaterats.
            </Alert>
          </Collapse>
        )}
        {(userPasswordReset.errorMessage?.length > 0 || userPasswordReset.result?.responseMessage === 'ERROR') && (
          <Collapse in={errorAlertOpen} sx={{ mb: 2, marginBottom: '56px' }}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setErrorAlertOpen(false);
                  }}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2, maxWidth: '1230px' }}
            >
              {userPasswordReset.errorMessage && userPasswordReset.errorMessage.indexOf("400") !== -1 ? "Det gick inte att uppdatera lösenordet. Kontrollera att du angett rätt befintligt lösenord och repeterat det nya lösenordet korrekt." : userPasswordReset.errorMessage}
            </Alert>
          </Collapse>
        )}
        <Card>
          <List>
            <ListItem sx={{ p: 3 }}>
              <ListItemText
                primaryTypographyProps={{ variant: 'h5', gutterBottom: true }}
                secondaryTypographyProps={{
                  variant: 'subtitle2',
                  lineHeight: 1,
                }}
                primary="Förbrukningsgrafer"
                secondary="Dölj förbrukningsgrafer"
              />
              <Switch color="primary" checked={state.hideGraphs} onChange={handleChange} name="hideGraphs" />
            </ListItem>
            <Divider component="li" />
            <ListItem sx={{ p: 3 }}>
              <ListItemText
              primaryTypographyProps={{ variant: 'h5', gutterBottom: true }}
              secondaryTypographyProps={{
                variant: 'subtitle2',
                lineHeight: 1,
              }}
              primary="Förvalt årsspann"
              secondary="Ange det standardantal år som ska visas i förbrukningsgraferna"
              />
              <TextField
              select
              label="Årsspann"
              value={state.graphFromYear}              
              onChange={handleChange}
              name="graphFromYear"
              SelectProps={{
                native: true,
              }}
              >
              {[1, 2, 3, 4, 5].map((option) => {
                const fromYear = new Date().getFullYear() - option;
                const toYear = new Date().getFullYear();
                return (
                <option key={fromYear} value={fromYear}>
                Visa grafer {option} år bakåt
                </option>
                )
              })}
              </TextField>
            </ListItem>
            <Divider component="li" />
            <ListItem sx={{ p: 3 }}>
              <ListItemText
                primaryTypographyProps={{ variant: 'h5', gutterBottom: true }}
                secondaryTypographyProps={{
                  variant: 'subtitle2',
                  lineHeight: 1,
                }}
                primary="Enhetstyp för vatten"
                secondary="Visa enhetstyp för vatten som m3 istället för liter vid visning av LOA och ATEMP."
              />
              <Switch color="primary" checked={state.showVolume} onChange={handleChange} name="showVolume" />
            </ListItem>
            <Divider component="li" />
            <ListItem sx={{ p: 3 }}>
              <ListItemText
                primaryTypographyProps={{ variant: 'h5', gutterBottom: true }}
                secondaryTypographyProps={{
                  variant: 'subtitle2',
                  lineHeight: 1,
                }}
                primary="Mörk/ljusläge"
                secondary="Ställ in appens utseende i mörkt eller ljust läge"
              />
              <Switch color="primary" defaultChecked={themeName === 'DarkTheme'} onChange={changeTheme} />
            </ListItem>
            <Divider component="li" />
            <ListItem sx={{ p: 3 }}>
              <ListItemText
                primaryTypographyProps={{ variant: 'h5', gutterBottom: true }}
                secondaryTypographyProps={{
                  variant: 'subtitle2',
                  lineHeight: 1,
                }}
                primary="Lösenordsåterställning"
                secondary="Återställ mitt lösenord"
              />
              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  name="oldPassword"
                  control={control}
                  defaultValue=""
                  render={({ field }) => <TextField {...field} type="password" label="Gammalt lösenord" variant="outlined" sx={{ marginLeft: '8px' }} />}
                />
                <Controller
                  name="newPassword"
                  control={control}
                  defaultValue=""
                  render={({ field }) => <TextField {...field} type="password" label="Nytt lösenord" variant="outlined" sx={{ marginLeft: '8px' }} />}
                />
                <Controller
                  name="confirmPassword"
                  control={control}
                  defaultValue=""
                  render={({ field }) => <TextField {...field} type="password" label="Bekräfta nytt lösenord" variant="outlined" sx={{ marginLeft: '8px' }} />}
                />
                <Button variant="contained" type="submit" color="primary" sx={{ marginLeft: '8px', marginTop: '8px' }}>
                  Återställ
                </Button>
              </form>
            </ListItem>
          </List>
        </Card>
      </Grid>
    </Grid>
  );
}

export default NotificationsTab;