import {useRef, useState} from 'react'
import {Avatar, Button, IconButton, Box, Divider, List, ListItem, ListItemText, Menu} from '@mui/material'
import {LockOpenTwoTone, AccountTreeTwoTone} from '@mui/icons-material'
import {UserBoxText, UserBoxLabel, UserBoxDescription, MenuUserBox} from './UserboxStyles'
import {NavLink, useNavigate} from 'react-router-dom'
import {IUserProps} from './IUserProps'
import { useDispatch } from 'react-redux'
import { logout } from '../../../../../redux/login/loginActions'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'

function HeaderUserbox(user: IUserProps) {
  const ref = useRef<any>(null)
  const [isOpen, setOpen] = useState<boolean>(false)
  const handleOpen = (): void => setOpen(true)
  const handleClose = (): void => setOpen(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isAuthenticated = useIsAuthenticated()
  const instance = useMsal()
  
  const handleOnLogOutClick = () => {
    if(isAuthenticated) {
      instance.instance.logoutRedirect({
        postLogoutRedirectUri: '/',
        onRedirectNavigate: (url) => {
          return false;
        } 
      })
      dispatch(logout)
      navigate("/login")
    }
    else {
      dispatch(logout)
      navigate("/login")
    }
  }

  return (
    <>
      <IconButton ref={ref} onClick={handleOpen} sx={{mr: 2}}>
        <Avatar>{user.userName.substring(0, 2).toUpperCase()} </Avatar>
      </IconButton>
      <Menu anchorEl={ref.current} onClose={handleClose} open={isOpen}>
        <MenuUserBox sx={{minWidth: 210}} display='flex'>
          <Avatar>{user.userName.substring(0, 2).toUpperCase()}</Avatar>
          <UserBoxText>
            <UserBoxLabel variant='body1'>{user.userName}</UserBoxLabel>
            <UserBoxDescription variant='body2'>{user.role}</UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{mb: 0}} />
        <List sx={{p: 1}} component='nav'>
          <ListItem button={true} to='/settings' component={NavLink}>
            <AccountTreeTwoTone fontSize='small' />
            <ListItemText primary='Inställningar' />
          </ListItem>
        </List>
        <Divider />
        <Box sx={{m: 1}}>
          <Button color='primary' sx={{'width': '100%'}} onClick={() => handleOnLogOutClick()}>
            <LockOpenTwoTone sx={{mr: 1}} />
            Sign out
          </Button>
        </Box>
      </Menu>
    </>
  )
}

export default HeaderUserbox
