import { IOffice } from '../redux/estateAttribute/interfaces';
import { isNullOrWhiteSpace } from '../shared/utils/utilities';
import saveupClient, { handleResponse } from '../clients/saveupClient';
import { IOwner } from '../redux/owner/ownerInterfaces';
const treeService = {
    getTree,
    getTreeFilteredByKeyword,
    getTreeFilteredByMeterType,
    getTreeFilteredByMeterTypeAndKeyword,
    filterNodesByOffice,
    hasNameClassValue,
    applyOfficeFilter,
    applyMeterTypeFilter,
    applyKeywordFilter,
    applyIdStringFilter,
    filterNodesByOwner
};
async function getTree() {     
    const response = await saveupClient.get(`/Estate/Tree`, { timeout: 120000 })
    .then(handleResponse)
    .catch(error => {
        console.log(error);
    });
    return response;
}
async function getTreeFilteredByKeyword(keyword: string) {    
    const response = await saveupClient.get(`/Estate/Tree/Filter?filter=${keyword}`, { timeout: 120000 })
    .then(handleResponse)
    .catch(error => {
        console.log(error);
    });
    return response;
}
async function getTreeFilteredByMeterType(meterTypeId: number) 
{    
    const response = await saveupClient.get(`/Estate/Tree/Meter/${meterTypeId}`, { timeout: 120000 })
    .then(handleResponse)
    .catch(error => {
        console.log(error);
    });
    return response;
}
async function getTreeFilteredByMeterTypeAndKeyword(meterTypeId: number, keyword: string) 
{    
    const response = await saveupClient.get(`Estate/Tree/Meter/${meterTypeId}/Filter?filter=${keyword}`, { timeout: 120000 })
    .then(handleResponse)
    .catch(error => {
        console.log(error);
    });
    return response;
}
function filterNodesByOffice(nodes: any, office: string) {
    if(isNullOrWhiteSpace(office) || office === "Alla kontor")
        return nodes

    const filteredNodes = nodes.map((node: any) => {        
        const filteredChildNodes = node.children.filter((child: any) => {
            if(child.data.type !== "ESTATE" || (child.data.attributes && hasNameClassValue(child.data.attributes, "OFFICE", "ESTATE", office)))
                return true
            else
                return false
        })

        if(filteredChildNodes && filteredChildNodes.length > 0) 
            node.children = filteredChildNodes
        else
            node.children = []
        return node
    })
    return filteredNodes.filter((node: any) => node.children?.length > 0)
}
function filterNodesByOwner(nodes: any[], owners: IOwner[]) {
    const filtered = nodes.filter((node: any) => node.data.type === "OWNER" && owners.some((owner: IOwner) => owner.id === node.data.id))
    return filtered
}
function hasNameClassValue(attributes: any[], attributeName: string, attributeClass: string, value: string) {
    if(value === "NOT_SET")
        return attributes.find(attribute => attribute.name === attributeName && attribute.class === attributeClass && !isNullOrWhiteSpace(value.trim())) ? false: true
    else
        return attributes.find(attribute => 
            attribute.name === attributeName && attribute.class === attributeClass && attribute.value.trim().toLowerCase() === value.trim().toLowerCase())
             ? true: false
}

function applyOfficeFilter(nodes: any[], office: string) {    
    return !isNullOrWhiteSpace(office) && office !== "Alla kontor " ? nodes.reduce((previous: any, current: any) => {      
        if(current.children?.length) {        
          const filtered: any[] = applyOfficeFilter(current.children, office)
          if(filtered.length) return [...previous, { ...current, children: filtered}]
        }
        const { children, ...itemWithoutChildren} = current    
        return (current.data.attributes && current.data.attributes.find((x: IOffice) => x.name.toLowerCase() === office.toLowerCase())) ? [...previous, itemWithoutChildren] : previous
      }, []) : nodes   
}

function applyMeterTypeFilter(nodes: any[], meterType: number) {
    return meterType > 0 ? nodes.reduce((previous: any, current: any) => {
        if(current.children?.length) {
          const filtered: any[] = applyMeterTypeFilter(current.children, meterType)
          if(filtered.length) return [...previous, { ...current, children: filtered}]
        }
        const { children, ...itemWithoutChildren} = current    
        return current.data.meterTypes.includes(meterType) ? [...previous, itemWithoutChildren] : previous
      }, []) : nodes       
}

function applyKeywordFilter(nodes: any[], query: string, isParentMatch = false) {
    return query ? nodes.reduce((previous: any, current: any) => {    
      const isMatch = isParentMatch || (current.text && current.text.toLowerCase().includes(query.toLowerCase())) || current.data.id === parseInt(query)
      if(current.children?.length) {
        const filtered: any[] = applyKeywordFilter(current.children, query, isMatch)
        if(filtered.length) return [...previous, { ...current, children: filtered}]
      }
      const { children, ...itemWithoutChildren} = current    
      return isMatch ? [...previous, itemWithoutChildren] : previous
    }, []) : nodes   
}
function applyIdStringFilter(nodes: any[], idString: string, isParentMatch = false) {
    return idString ? nodes.reduce((previous: any, current: any) => {    
      const isMatch = isParentMatch || current.data.id === parseInt(idString.split(":")[1])
      if(current.children?.length) {
        const filtered: any[] = applyIdStringFilter(current.children, idString, isMatch)
        if(filtered.length) return [...previous, { ...current, children: filtered}]
      }
      const { children, ...itemWithoutChildren} = current    
      return isMatch ? [...previous, itemWithoutChildren] : previous
    }, []) : nodes   
}

export default treeService;
