import React from 'react'
import {Link} from 'react-router-dom'

const NotFound = () => {
    return (
        <div>
        Tyvärr kunde sidan inte hittas. <Link to='/'>Gå tillbaka.</Link>
        </div>
    )
}
export default NotFound