import React, {useEffect, useState} from 'react'
import {CreateUserProps} from './interfaces'
import {Controller, useFormContext} from 'react-hook-form'
import {EditUserForm, UserRole} from '../../../redux/user/interfaces'
import PageTitleWrapper from '../../../components/shared/PageTitleWrapper'
import {Button, Card, CardActions, CardContent, CardHeader, Container, FormControlLabel, Grid, Stack, Switch, TextField, Typography} from '@mui/material'
import {Delete, Lock, Save} from '@mui/icons-material'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import ControlledDropDown from '../../../components/shared/Inputs/ControlledDropDown'
import {ArrowBack} from '@mui/icons-material'
import {Link} from 'react-router-dom'
import ControlledCheckDropDown from '../../../components/shared/Inputs/ControlledCheckDropDown'
const UserDetails: React.FC<CreateUserProps> = (props: CreateUserProps) => {
  const methods = useFormContext<EditUserForm>()
  const [showOwners, setShowOwners] = useState<boolean>(false)
  const {setValue, watch} = useFormContext<EditUserForm>()
  const watchActivation = watch('requireActivation')
  const watchSendMail = watch('sendMail')
  useEffect(() => {
    setShowOwners(false)
    if (props.roles) {
      const role = props.roles.find((x: UserRole) => ['reader', 'readers'].some((y) => y === x.name.toLocaleLowerCase()))
      if (role) {
        setValue('userRoleId', role.id)
      }
    }
  }, [props.roles])

  const onRoleChange = (value: any) => {
    const role = props.roles.find((x: UserRole) => x.id === value)
    if (role) {
      if (['customer', 'customers'].some((x) => x === role.name.toLocaleLowerCase())) {
        setShowOwners(true)
      } else {
        setShowOwners(false)
      }
    }
  }

  // Render and return a div form with all the necessary elements in the EditUserForm
  return (
    <Grid sx={{margin: '20px'}} justifyContent='center' alignItems='stretch'>
      <Container maxWidth='lg'>
        <Card>
          <CardHeader title='Skapa användare' />
          <CardContent>
            <Grid container spacing={5}>
              <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={5}>
                <Grid item xs={2}>
                  <Controller name='fullName' control={methods.control} render={({field}) => <TextField fullWidth label='Namn' {...field} />} />
                </Grid>
                <Grid item xs={2}>
                  <Controller
                    name='mail'
                    control={methods.control}
                    render={({field, fieldState}) => <TextField fullWidth label='Användarnamn' {...field} error={fieldState.invalid} helperText={fieldState.invalid ? 'Ogiltigt användarnamn.' : ''} />}
                    rules={{
                      pattern: {
                        value: /^[A-Z0-9._%+-@]+$/i,
                        message: 'Invalid username',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Controller name='password' control={methods.control} render={({field}) => <TextField fullWidth label='Lösenord' type='password' {...field} disabled={watchActivation && watchSendMail} />} />
                </Grid>
                <Grid item xs={2}>
                  <Controller
                    name='userPrincipalName'
                    control={methods.control}
                    render={({field, fieldState}) => (
                      <TextField fullWidth label='Microsoft UPN' {...field} error={fieldState.invalid} helperText={fieldState.invalid ? 'Ogiltigt user principal name.' : ''} />
                    )}
                    rules={{
                      pattern: {
                        value: /^[A-Z0-9._%+-@]+$/i,
                        message: 'Invalid user principal name',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    sx={{marginTop: '10px'}}
                    control={
                      <Controller
                        name='sendMail'
                        control={methods.control}
                        render={({field}) => <Switch {...field} checked={field.value} onChange={(e) => field.onChange(e.target.checked)} title='Mailutskick' />}
                      />
                    }
                    label='Mailutskick'
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    sx={{marginTop: '10px'}}
                    control={
                      <Controller
                        name='requireActivation'
                        control={methods.control}
                        render={({field}) => <Switch {...field} checked={field.value} onChange={(e) => field.onChange(e.target.checked)} title='Självaktivering' />}
                      />
                    }
                    label='Självaktivering'
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={5}>
                <Grid item xs={2}>
                  {props.roles?.length > 0 && (
                    <ControlledDropDown
                      defaultValue={props.roles[0].name}
                      control={methods.control}
                      name='userRoleId'
                      label='Roll'
                      items={props.roles.map((x: any, index: number) => ({value: x.id, text: x.name, key: index}))}
                      onChange={onRoleChange}
                    />
                  )}
                </Grid>
                <Grid item xs={2}>
                  {props.offices?.length > 0 && (
                    <ControlledDropDown
                      defaultValue={props.offices[0].name}
                      control={methods.control}
                      name='office'
                      label='Kontor'
                      items={props.offices.map((x: any, index: number) => ({value: x.id, text: x.name, key: index}))}
                    />
                  )}
                </Grid>
                <Grid item xs={3}>
                  {showOwners && (
                    <ControlledCheckDropDown
                      control={methods.control}
                      name='owner'
                      label='Fastigetsägare'
                      items={props.owners.map((x: any, index: number) => ({value: x.id, text: x.name, key: index}))}
                    />
                  )}
                </Grid>
              </Grid>
              {showOwners && (
                <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={5}>
                  <Grid item xs={2}>
                    <FormControlLabel
                      sx={{marginTop: '10px'}}
                      control={
                        <Controller
                          name='canEdit'
                          control={methods.control}
                          render={({field}) => {
                            return <Switch checked={field.value} onChange={(e) => field.onChange(e.target.checked)} title='Kan redigera' />
                          }}
                        />
                      }
                      label='Kan redigera'
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <FormControlLabel
                      sx={{marginTop: '10px'}}
                      control={
                        <Controller
                          name='canReadDeviations'
                          control={methods.control}
                          render={({field}) => {
                            return <Switch checked={field.value} onChange={(e) => field.onChange(e.target.checked)} title='Kan se avvikelser' />
                          }}
                        />
                      }
                      label='Kan se avvikelser'
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </CardContent>
          <CardActions>
            <Grid item xs={12}>
              <Stack direction='row' spacing={2}>
                <Button sx={{mt: {xs: 2, md: 0}}} variant='contained' type='submit' color='success' startIcon={<SaveOutlinedIcon fontSize='small' />}>
                  Spara
                </Button>
                <Link to='/user'>
                  <Button sx={{mt: {xs: 2, md: 0}}} variant='contained' type='submit' color='secondary' startIcon={<ArrowBack fontSize='small' />}>
                    Tillbaka
                  </Button>
                </Link>
              </Stack>
            </Grid>
          </CardActions>
        </Card>
      </Container>
    </Grid>
  )
}
export default UserDetails
