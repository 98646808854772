import React, {useEffect, useState} from 'react'
import {EditUserForm, UpdateUserState, UserByIdState, UserListItem, UserRole, UserRoleState} from '../../../redux/user/interfaces'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../redux/rootReducer'
import {useNavigate, useParams} from 'react-router'
import {createUserReset, getUserById, getUserRoles, updatePassword, updateUser} from '../../../redux/user/actions'
import {IOffice} from '../../../redux/estateAttribute/interfaces'
import UserDetails from './UserDetails'
import {FormProvider, SubmitHandler, useForm, useFormContext} from 'react-hook-form'
import {IOwner} from '../../../redux/owner/ownerInterfaces'
import {getOwners} from '../../../redux/owner/ownerActions'
import {propsAreEqual} from '../../../shared/utils/utilities'
import { MAIL_SEND_FREQUENCY_EXCEEDED, OK } from '../../../shared/utils/constants'

const defaultUserListItem: UserListItem = {
  id: 0,
  fullName: '',
  mail: '',
  userRoleId: 0,
  role: '',
  insDate: new Date(),
  updDate: new Date(),
  isDisabled: false,
  office: '',
  owners: [],
  canEdit: false,
  canReadDeviations: false,
}
const UserDetailsContainer = () => {
  const {id} = useParams()
  const userId: number = id ? parseInt(id) : 0
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const methods = useForm<EditUserForm>({
    defaultValues: {
      id: 0,
      fullName: '',
      mail: '',
      userRoleId: 0,
      isDisabled: false,
      office: '',
      owners: [],
      insDate: new Date(),
      updDate: new Date(),
      canEdit: false,
      canReadDeviations: false,
      userPrincipalName: ''
    },
  })

  const {handleSubmit} = useForm<EditUserForm>()
  const {setValue, reset, watch} = methods

  const offices: IOffice[] = useSelector((state: RootState) => state.filter.offices)
  const userByIdState: UserByIdState = useSelector((state: RootState) => state.user.userById)
  const userRoleState: UserRoleState = useSelector((state: RootState) => state.user.userRoles)
  const [userListItem, setUserListItem] = useState<UserListItem>(defaultUserListItem)
  const owners: IOwner[] = useSelector((state: RootState) => state.owner.owners)
  const availableUserRoles: UserRole[] = useSelector((state: RootState) => state.user.userRoles.userRoles)

  const createUserResetResult = useSelector((state: RootState) => state.user.createUserReset)

  const afterUserUpdateState: UpdateUserState = useSelector((state: RootState) => state.user.updateUser)
  const [errorMessage, setErrorMessage] = useState<string | undefined>('')
  const [successMessage, setSuccessMessage] = useState<string | undefined>('')

  useEffect(() => {
    setSuccessMessage('')
    setErrorMessage('')
    dispatch(getUserById(userId))
    dispatch(getOwners())
  }, [userId])

  useEffect(() => {
    reset(userByIdState.user)
  }, [userByIdState.user])

  useEffect(() => {
    setSuccessMessage('')
    setErrorMessage('')
    dispatch(getUserRoles())
  }, [])

  useEffect(() => {
    if (afterUserUpdateState.isLoading) return
    else if (propsAreEqual(afterUserUpdateState.user, userByIdState.user)) {
      navigate('/user')
    } else {
      setErrorMessage(afterUserUpdateState.errorMessage)
    }
  }, [afterUserUpdateState])

  useEffect(() => {
    console.log(createUserResetResult)
    if(createUserResetResult.errorMessage) {
      setErrorMessage(createUserResetResult.errorMessage)
    }
    if(createUserResetResult.result?.userId == userId) {
      if(createUserResetResult.result?.result === OK) {
        setSuccessMessage(`Återställningsmail skickades till ${createUserResetResult.result.sentMail.recipient}`)
      }
      else if(createUserResetResult.result?.result === MAIL_SEND_FREQUENCY_EXCEEDED) {
        setErrorMessage(`Vänligen vänta 5 minuter innan ytterligare utskick görs.`)
      }
    }
  }, [createUserResetResult])

  const onSaveUser: SubmitHandler<EditUserForm> = (data: EditUserForm) => {
    dispatch(
      updateUser(
        data.id,
        data.mail,
        data.fullName,
        data.userRoleId,
        data.office,
        data.owners.map((x) => parseInt(x)),
        data.canEdit,
        data.canReadDeviations,
        data.userPrincipalName
      )
    )
  }

  const onUpdatePassword = (password: string) => {
    if (userByIdState.user) {
      dispatch(updatePassword(userByIdState.user?.id, password))
    }
  }

  const onSendInvite = (userId: number) => {  
    if (userId) {
      dispatch(createUserReset(userId))
    }
  }

  return userListItem && userRoleState.userRoles ? (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSaveUser)}>
        <UserDetails
          userId={userByIdState.user ? userByIdState.user.id : 0}
          roles={availableUserRoles ? availableUserRoles.sort((x: UserRole, y: UserRole) => x.name.localeCompare(y.name)) : []}
          offices={offices}
          owners={owners.sort((x: IOwner, y: IOwner) => x.name.localeCompare(y.name))}
          updatePassword={onUpdatePassword}
          successMessage={successMessage}
          errorMessage={errorMessage}
          sendInvite={onSendInvite}
        />
      </form>
    </FormProvider>
  ) : (
    <div>Loading...</div>
  )
}
export default UserDetailsContainer
