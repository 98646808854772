import axios from 'axios'
import userService from '../../services/userService'
import IAttribute from '../attribute/interfaces'
import {CreateUserResult, UserAttribute, UserRole} from './interfaces'
import { DEVIATION_READ, NODE_WRITE, OFFICE, UPN, USER } from '../../shared/utils/constants'

export const createUserAttribute = async (userId: number, attributeId: number, value: string): Promise<UserAttribute | undefined> => {
  try {
    return await userService.createUserAttribute(userId, attributeId, value)
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(error.message)
    }
    return undefined
  }
}
export const handleRoleSpecificAttributes = async (
  userId: number,
  userRoleId: number,
  shouldEdit: boolean,
  shouldReadDeviations: boolean,
  userPrincipalName: string,
  office: string,
  attributes: IAttribute[],
  roles: UserRole[]
): Promise<CreateUserResult> => {
  const result: CreateUserResult = {
    createdUser: undefined,
    errorMessage: '',
    createOffice: {success: true, errorMessage: ''},
    createOwner: {success: true, errorMessage: ''},
    canEdit: {success: true, errorMessage: ''},
    canRead: {success: true, errorMessage: ''},
    upn: {success: true, errorMessage: ''},
    mail: {success: true, errorMessage: ''},
    requireActivation: { success: true, errorMessage: ''},
  }
  const roleOfCreatedUser = roles.find((role: UserRole) => role.id === userRoleId)
  const upnAttribute = attributes.find((x: IAttribute) => x.class === USER && x.name === UPN)
  const officeAttribute = attributes.find((x: IAttribute) => x.class === USER && x.name === OFFICE)

  if (officeAttribute) {
    result.createOffice.attribute = await createUserAttribute(userId, officeAttribute.id, office)
    result.createOffice.success = !!result.createOffice.attribute
  }
  if (roleOfCreatedUser && (roleOfCreatedUser.name.toLocaleUpperCase() === 'CUSTOMER' || roleOfCreatedUser.name.toLocaleUpperCase() === 'CUSTOMERS')) {
    const canEditAttribute = attributes.find((x: IAttribute) => x.class ===  USER && x.name === NODE_WRITE)
    const canReadDeviationAttribute = attributes.find((x: IAttribute) => x.class === USER && x.name === DEVIATION_READ)

    if (canEditAttribute) {
      result.canEdit.attribute = await createUserAttribute(userId, canEditAttribute.id, shouldEdit.toString())
      result.canEdit.success = !!result.canEdit.attribute
    }

    if (canReadDeviationAttribute) {
      result.canRead.attribute = await createUserAttribute(userId, canReadDeviationAttribute.id, shouldReadDeviations.toString())
      result.canRead.success = !!result.canRead.attribute
    }
  }
  if (upnAttribute && userPrincipalName) {
    result.upn.attribute = await createUserAttribute(userId, upnAttribute.id, userPrincipalName)
    result.upn.success = !!result.upn.attribute
  }

  return result
}
