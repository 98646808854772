import SecTextField from '../shared/Inputs/SecTextField'
import {Cancel, Save, Send} from '@mui/icons-material'
import {
  TextField,
  FormControl,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  SelectChangeEvent,
} from '@mui/material'
import {getLogCase, createLogCase, getCaseStatuses, getCaseTypes, updateLogCase, createPost} from '../../redux/case/actions'
import {ICase, ICaseCreateParameter, ICaseModalProps, ICaseStatus, IPost, IPostCreateParameter, ICaseForm, ICaseUpdateParameter} from '../../redux/case/interfaces'
import {RootState} from '../../redux/rootReducer'
import {hasEditPermissions, hasPermissions} from '../../shared/utils/utilities'
import {first} from 'lodash'
import React, {useEffect, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import CasePost from './CasePost'
import ControlledDropDown from '../shared/Inputs/ControlledDropDown'
import {NODE_WRITE, categoryConstants} from '../../shared/utils/constants'
import { updateLogTags } from '../../redux/home/homeActions'
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const defaultCaseForm: ICaseForm = {
  id: 0,
  title: '',
  description: '',
  status: 'OPEN',
  type: 'RESET',
  category: 'OTHER',
  comment: '',
}

const Case = (props: ICaseModalProps) => {
  const dispatch = useDispatch()

  const [open, setOpen] = useState(props.open)
  const [posts, setPosts] = useState<IPost[]>([])
  const [selectedStatus, setSelectedStatus] = useState('OPEN')
  const [selectedType, setSelectedType] = useState('RESET')
  const [saved, setSaved] = useState(false)
  const [updated, setUpdated] = useState(false)
  const [comment, setComment] = useState('')
  const [newComments, setNewComments] = useState<IPostCreateParameter[]>([])

  const logCase = useSelector((state: RootState) => state.case.selectedCase)
  const caseStatuses = useSelector((state: RootState) => state.case.caseStatuses)
  const caseTypes = useSelector((state: RootState) => state.case.caseTypes)
  const [isDisabled, setIsDisabled] = useState<boolean>(false)

  const {register, control, handleSubmit, reset, watch} = useForm<ICaseForm>({defaultValues: defaultCaseForm})

  // Watch the status value from the form
  const watchStatus = watch('status')

  useEffect(() => {
    dispatch(getCaseTypes())
    dispatch(getCaseStatuses())
  }, [])

  useEffect(() => {
    if (saved && logCase) {      
      props.onConfirm(props.id, props.isArchived, logCase.caseState?.name ?? 'OPEN', props.isReading)
    }
    else if(updated) {
      props.onConfirm(props.id, props.isArchived, watchStatus ?? 'OPEN', props.isReading)
    }
    else {
      reset({
        id: logCase ? logCase.id : 0,
        title: logCase ? logCase.name : '',
        description: logCase ? logCase.description : '',
        status: logCase ? logCase.caseState?.name : 'OPEN',
        type: 'DEVIATION',
        category: props.category,
        comment: '',
      })
    }
  }, [saved, logCase])

  useEffect(() => {
    setSaved(false)
    setUpdated(false)
    setComment('')
    setNewComments([])
    if (props.id > 0) {
      reset({
        id: 0,
        title: '',
        description: '',
        status: props.status ? props.status : 'OPEN',
        type: 'DEVIATION',
        category: props.category,
        comment: '',
      })
      dispatch(getLogCase(props.id, props.isArchived, props.isReading))
      setIsDisabled(!hasPermissions([NODE_WRITE]))
    }
  }, [props.id])

  const onSubmit = (data: ICaseForm) => {
    const type = first(caseTypes.filter((x) => x.name === props.type || 'DEVIATION'))
    const status = first(caseStatuses.filter((x) => x.name === data.status))
    if (!type || !status) return

    if (logCase && logCase.id > 0) {
      const parameter: ICaseUpdateParameter = {
        id: logCase.id,
        caseTypeId: type.id,
        caseStateId: status.id,
        name: data.title ? data.title : '',
        description: data.description,
        isActive: status.name === 'OPEN',
      }
      dispatch(updateLogCase(parameter))
      dispatch(createPost(logCase.id, newComments))
      setUpdated(true)
    } else {
      const parameter: ICaseCreateParameter = {
        caseTypeId: type.id,
        caseStateId: status.id,
        counterValueLogId: props.isArchived ? 0 : props.id,
        counterPeriodConsumptionLogId: props.isArchived ? 0 : props.id,
        name: data.title ? data.title : '',
        description: data.description,
        counterValueLogHistoryId: props.isArchived ? props.id : 0,
        counterPeriodConsumptionLogHistoryId: props.isArchived ? props.id : 0,
        posts: newComments,
      }
      dispatch(createLogCase(parameter, !props.isReading, props.isArchived))
      setSaved(true)
    }

    if(props.id > 0 && data.category !== props.category) {
      dispatch(updateLogTags(props.id, [data.category]))
    }
  }

  const renderCasePost = (post: IPost, index: number) => {
    return (
      <Grid container direction='row' alignItems='stretch' spacing={3} sx={{marginTop: '2px'}} key={`${index}`}>
        <Grid xs={12} item>
          <CasePost body={post.body} fullName={post.user.fullName} updDate={post.updDate} isSaved={true}></CasePost>
        </Grid>
      </Grid>
    )
  }

  const renderTemporaryCasePost = (post: IPostCreateParameter, index: number) => {
    return (
      <Grid container direction='row' alignItems='stretch' spacing={3} sx={{marginTop: '2px'}} key={`${index}`}>
        <Grid xs={12} item>
          <CasePost body={post.body} fullName={'n/a'} updDate={new Date()} isSaved={false}></CasePost>
        </Grid>
      </Grid>
    )
  }

  const onSaveCommentClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setNewComments((comments) => [
      ...newComments,
      {
        caseId: logCase ? logCase.id : 0,
        title: '',
        subject: '',
        body: comment,
      },
    ])
  }

  const renderCaseModalContent = () => {
    return (
      <div style={{width: '400px', height: 'auto'}}>
        <Grid container direction='column' spacing={2}>
          <Grid container direction='row' alignItems='stretch' spacing={3} sx={{marginTop: '2px'}}>
            <Grid xs={12} item>
              <Typography sx={{fontStyle: 'italic'}}>Meddelande: {props.logMessage}</Typography>
            </Grid>
          </Grid>
          <Grid container direction='row' alignItems='stretch' spacing={3} sx={{marginTop: '2px'}}>
            <Grid xs={12} item>
              <ControlledDropDown label='Status' defaultValue='OPEN' items={[{value: 'OPEN', text: 'Pågående', key: 'OPEN'}, { value: 'CLOSED', text: 'Avslutad', key: 'CLOSED'}, { value: 'NEW', text: 'Ny', key: 'NEW'}]} name='status' control={control} />
            </Grid>
          </Grid>
          <Grid container direction='row' alignItems='stretch' spacing={3} sx={{marginTop: '2px'}}>
            <Grid xs={12} item>
              <ControlledDropDown label='Kategori' defaultValue='OTHER' items={categoryConstants.filter(x => x.value !== 'ALL')} name='category' control={control} />
            </Grid>
          </Grid>
          <Grid container direction='row' alignItems='stretch' spacing={3} sx={{marginTop: '2px'}}>
            <Grid xs={12} item>
              <Controller
                render={({field}) => <TextField variant='outlined' fullWidth label='Beskrivning' InputLabelProps={{shrink: true}} multiline={true} rows={3} {...field} />}
                name='description'
                control={control}
              />
            </Grid>
          </Grid>
          <Divider sx={{marginTop: '20px', marginBottom: '10px'}} />
          <Grid container direction='row' alignItems='stretch' spacing={3} sx={{marginTop: '2px'}}>
            <Grid xs={12} item>
              <TextField
                name='comment'
                id='comment'
                variant='outlined'
                fullWidth
                label='Skriv kommentar'
                InputLabelProps={{shrink: true}}
                multiline={true}
                value={comment}
                onChange={(e: any) => setComment(e.target.value)}
              />
              {/* <Controller
                render={({field}) => <TextField variant='outlined' fullWidth label='Skriv kommentar' InputLabelProps={{shrink: true}} multiline={true} {...field} />}
                name='comment'
                control={control}
              /> */}
            </Grid>
          </Grid>
          <Grid container direction='row' alignItems='stretch' spacing={3} sx={{marginTop: '2px'}}>
            <Grid xs={12} item>
              {logCase?.posts?.map((post, index) => renderCasePost(post, index))}
              {newComments.map((post, index) => renderTemporaryCasePost(post, index))}
            </Grid>
          </Grid>
          <Grid container direction='row' alignItems='stretch' spacing={3} sx={{marginTop: '2px'}}>
            <Grid xs={12} item>
              <Button sx={{mt: {xs: 2, md: 0}}} variant='contained' color='secondary' startIcon={<Send fontSize='small' />} onClick={onSaveCommentClick} disabled={isDisabled}>
                Lägg till kommentar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }

  return (
    <form>
      <Dialog sx={{style}} open={props.open}>
        <DialogTitle>
          <Typography variant='h3'>{logCase ? 'Ärende' : 'Nytt ärende'}</Typography>
        </DialogTitle>
        <DialogContent>{renderCaseModalContent()}</DialogContent>
        <DialogActions>
          <Button sx={{mt: {xs: 2, md: 0}}} variant='contained' color='primary' startIcon={<Save fontSize='small' />} disabled={isDisabled} onClick={handleSubmit(onSubmit)}>
            OK
          </Button>
          <Button sx={{mt: {xs: 2, md: 0}}} variant='contained' color='primary' startIcon={<Cancel fontSize='small' />} onClick={props.onClose}>
            Avbryt
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  )
}
export default Case
