import React, {useEffect, useState} from 'react'
import {UserDetailsProps} from './interfaces'
import {Controller, useFormContext} from 'react-hook-form'
import {EditUserForm, UserRole} from '../../../redux/user/interfaces'
import PageTitleWrapper from '../../../components/shared/PageTitleWrapper'
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import {Close, Delete, Lock, LockOpen, Mail, Save} from '@mui/icons-material'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import ControlledDropDown from '../../../components/shared/Inputs/ControlledDropDown'
import ControlledCheckDropDown from '../../../components/shared/Inputs/ControlledCheckDropDown'
import {ArrowBack} from '@mui/icons-material'
import {Link} from 'react-router-dom'
import {set} from 'lodash'
import {isNullOrWhiteSpace} from '../../../shared/utils/utilities'
const UserDetails: React.FC<UserDetailsProps> = (props: UserDetailsProps) => {
  const methods = useFormContext<EditUserForm>()
  const [showOwners, setShowOwners] = useState<boolean>()
  const {setValue, watch} = useFormContext<EditUserForm>()
  const formUserId = watch('id')
  const [password, setPassword] = useState('')
  const [resetModalOpen, setResetModalOpen] = useState<boolean>(false)
  const [inviteModalOpen, setInviteModalOpen] = useState<boolean>(false)
  const [alertOpen, setAlertOpen] = useState<boolean>(false)

  useEffect(() => {
    const owners = watch('owners')
    const userRoleId = watch('userRoleId')
    // Check whether the current userRoleId is that of the role 'customer' or 'customers'
    const role = props.roles?.find((x: UserRole) => x.id === userRoleId)
    if (role && (role.name.toLocaleLowerCase() === 'customer' || role.name === 'customers')) {
      setShowOwners(true)
    } else {
      setShowOwners(false)
    }
    if (owners) {
      setValue('owners', owners)
    }
  }, [formUserId])

  const onRoleChange = (value: any) => {
    if (formUserId) {
      const role = props.roles.find((x: UserRole) => x.id === value)
      if (role) {
        if (['customer', 'customers'].some((x) => x === role.name.toLocaleLowerCase())) {
          setShowOwners(true)
        } else {
          setShowOwners(false)
        }
      }
    }
  }

  useEffect(() => {
    if (!isNullOrWhiteSpace(props.errorMessage) || !isNullOrWhiteSpace(props.successMessage)) setAlertOpen(true)
    else setAlertOpen(false)
  }, [props.errorMessage, props.successMessage])

  const renderAlert = () => {
    console.log(props.errorMessage, props.successMessage)
    let alert = <div></div>
    if (!isNullOrWhiteSpace(props.errorMessage)) {
      alert = (
        <Alert
          severity='error'
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={() => {
                setAlertOpen(false)
              }}>
              <Close fontSize='inherit' />
            </IconButton>
          }
          sx={{mb: 2, width: '714px'}}>
          {props.errorMessage}
        </Alert>
      )
    } else if (!isNullOrWhiteSpace(props.successMessage)) {
      alert = (
        <Alert
          severity='success'
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={() => {
                setAlertOpen(false)
              }}>
              <Close fontSize='inherit' />
            </IconButton>
          }
          sx={{mb: 2, width: '714px'}}>
          {props.successMessage}
        </Alert>
      )
    }
    return <Collapse in={alertOpen}>{alert}</Collapse>
  }

  // Render and return a div form with all the necessary elements in the EditUserForm
  return (
    <React.Fragment>
      <Grid sx={{margin: '20px'}} justifyContent='center' alignItems='stretch'>
        <Container maxWidth='lg'>
          <Card>
            <CardHeader title='Redigera användare' />
            <CardContent>
              <Grid container spacing={5}>
                <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={2} marginBottom={5}>
                  <Grid item xs={12}>
                    {renderAlert()}
                  </Grid>
                </Grid>
                <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={5}>
                  <Grid item xs={2}>
                    <Controller name='id' control={methods.control} render={({field}) => <TextField fullWidth label='Id' {...field} disabled />} />
                  </Grid>
                  <Grid item xs={2}>
                    <Controller name='fullName' control={methods.control} render={({field}) => <TextField fullWidth label='Namn' {...field} />} />
                  </Grid>
                  <Grid item xs={2}>
                    <Controller
                      name='mail'
                      control={methods.control}
                      render={({field, fieldState}) => (
                        <TextField fullWidth label='Användarnamn' {...field} error={fieldState.invalid} helperText={fieldState.invalid ? 'Ogiltigt användarnamn.' : ''} />
                      )}
                      rules={{
                        pattern: {
                          value: /^[A-Z0-9._%+-@]+$/i,
                          message: 'Invalid username',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Controller
                      name='userPrincipalName'
                      control={methods.control}
                      render={({field, fieldState}) => (
                        <TextField fullWidth label='Microsoft UPN' {...field} error={fieldState.invalid} helperText={fieldState.invalid ? 'Ogiltigt user principal name.' : ''} />
                      )}
                      rules={{
                        pattern: {
                          value: /^[A-Z0-9._%+-@]+$/i,
                          message: 'Invalid user principal name',
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={5}>
                  <Grid item xs={2}>
                    {props.roles?.length > 0 && (
                      <ControlledDropDown
                        defaultValue={props.roles[0].name}
                        control={methods.control}
                        name='userRoleId'
                        label='Roll'
                        items={props.roles.map((x: any, index: number) => ({value: x.id, text: x.name, key: index}))}
                        onChange={onRoleChange}
                      />
                    )}
                  </Grid>
                  <Grid item xs={2}>
                    {props.offices?.length > 0 && (
                      <ControlledDropDown
                        defaultValue={props.offices[0].name}
                        control={methods.control}
                        name='office'
                        label='Kontor'
                        items={props.offices.map((x: any, index: number) => ({value: x.id, text: x.name, key: index}))}
                      />
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    {showOwners && (
                      <ControlledCheckDropDown
                        control={methods.control}
                        name='owner'
                        label='Fastigetsägare'
                        items={props.owners.map((x: any, index: number) => ({value: x.id, text: x.name, key: index}))}
                      />
                    )}
                  </Grid>
                </Grid>
                {showOwners && (
                  <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={5}>
                    <Grid item xs={2}>
                      <FormControlLabel
                        sx={{marginTop: '10px'}}
                        control={
                          <Controller
                            name='canEdit'
                            control={methods.control}
                            render={({field}) => {
                              return <Switch checked={field.value} onChange={(e) => field.onChange(e.target.checked)} title='Kan rediera' />
                            }}
                          />
                        }
                        label='Kan redigera'
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <FormControlLabel
                        sx={{marginTop: '10px'}}
                        control={
                          <Controller
                            name='canReadDeviations'
                            control={methods.control}
                            render={({field}) => {
                              return <Switch checked={field.value} onChange={(e) => field.onChange(e.target.checked)} title='Kan rediera' />
                            }}
                          />
                        }
                        label='Kan se avvikelser'
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </CardContent>
            <CardActions>
              <Grid item xs={12}>
                <Stack direction='row' spacing={2}>
                  <Button sx={{mt: {xs: 2, md: 0}}} variant='contained' type='submit' color='success' startIcon={<SaveOutlinedIcon fontSize='small' />}>
                    Spara
                  </Button>
                  <Link to='/user'>
                    <Button sx={{mt: {xs: 2, md: 0}}} variant='contained' type='submit' color='secondary' startIcon={<ArrowBack fontSize='small' />}>
                      Tillbaka
                    </Button>
                  </Link>
                  <Button
                    variant='contained'
                    color='primary'
                    startIcon={<LockOpen />}
                    onClick={() => {
                      setPassword('')
                      setResetModalOpen(true)
                    }}
                    sx={{marginTop: '8px'}}>
                    Återställ lösenord
                  </Button>
                  <Button variant='contained' color='primary' startIcon={<Mail />} onClick={() => setInviteModalOpen(true)} sx={{marginTop: '8px'}}>
                    Skicka ny inbjudan
                  </Button>
                </Stack>
              </Grid>
            </CardActions>
          </Card>
        </Container>
      </Grid>
      <Dialog open={resetModalOpen} onClose={() => setResetModalOpen(false)} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>
          <Typography variant='h4'>{'Återställ lösenord'}</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <TextField value={password} fullWidth label='Lösenord' type='password' variant='outlined' onChange={(e) => setPassword(e.target.value)} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              props.updatePassword(password)
              setResetModalOpen(false)
            }}
            color='primary'
            autoFocus>
            Återställ
          </Button>
          <Button
            onClick={() => {
              setResetModalOpen(false)
            }}>
            Avbryt
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={inviteModalOpen} onClose={() => setInviteModalOpen(false)} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>
          <Typography variant='h4'>{'Återställ och skicka inbjudan'}</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Detta kommer att återställa användarens konto och skicka en ny inbjudan till SaveUp. Användaren kommer att behöva aktivera sitt konto igen.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              props.sendInvite(formUserId)
              setInviteModalOpen(false)
            }}
            color='primary'
            autoFocus>
            OK
          </Button>
          <Button
            onClick={() => {
              setInviteModalOpen(false)
            }}>
            Avbryt
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
export default UserDetails
