import {IOwner} from '../redux/owner/ownerInterfaces'
import saveupClient, {handleResponse} from '../clients/saveupClient'
import {ILogin, IAuth} from '../redux/login/loginInterfaces'
import {CreateUserParameter, IUser, UserSendMailDto, UpdateUserParameter, UserAttribute, UserListItem, UserOwnerDto, UserOwnerParameter, UserRole, UserResetDto} from '../redux/user/interfaces'
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { SAVEUP_TOKEN_KEY } from '../shared/utils/constants'
const userService = {  
  login,
  loginV2,
  logout,
  search,
  getMe,
  getUsers,
  getUserById,
  getUsersListIitems,
  getCurrentUser,
  getCurrentUserOwners,
  getUserRoles,
  getUserAttributes,
  getOwnersByUserId,
  getPendingUserReset,
  createUser,
  createUserV2,
  createUserAttribute,
  createUserOwner,
  createUserReset,
  createUserResetAndMail,
  createUserInvite,
  updateUser,
  updateUserAttribute,
  updateUserOwners,
  updateUserPassword,
  deleteUser,
  updateUserState,
  updateCurrentUserPassword,
  sendCurrentUserPasswordReset,
  resolvePendingUserReset,
  resolvePendingUserActivation
}

async function login(data: ILogin) {
 return await saveupClient.get<AxiosResponse>(`/User/Login?username=${data.username}&password=${data.password}`)
}

async function loginV2(data: ILogin) {
  return await saveupClient.post<AxiosResponse>(`/v2/token?username=${data.username}&password=${data.password}`)
}

async function search(keyword: string) {
  return await saveupClient.get<IUser[]>(`user/search?keyword=${keyword}`).then(handleResponse)
}
function logout() {
  // Clear both session storage and local storage.
  sessionStorage.removeItem(SAVEUP_TOKEN_KEY)
  localStorage.removeItem('auth')
}

async function getCurrentUser() {
  return await saveupClient.get<IUser>('user/current').then(handleResponse)
}

async function getCurrentUserOwners() {
  return await saveupClient.get<IOwner>(`user/owner`).then(handleResponse)
}

async function getUsers() {
  return await saveupClient.get<IUser[]>(`user`).then(handleResponse)
}

async function getUsersListIitems() {
  return await saveupClient.get<UserListItem[]>(`user/attribute/list`).then(handleResponse)
}

async function getUserById(id: number) {
  return await saveupClient.get<IUser>(`user/${id}`).then(handleResponse)
}

async function getUserAttributes(userId: number) {
  return await saveupClient.get<UserAttribute[]>(`user/${userId}/attribute`).then(handleResponse)
}

async function getUserRoles() {
  return await saveupClient.get<UserRole[]>(`userrole`).then(handleResponse)
}

async function getMe() {
 return await saveupClient.get<string[]>(`user/me`).then(handleResponse)
}

async function getOwnersByUserId(id: number) {
  return await saveupClient.get<IOwner[]>(`user/${id}/owner`).then(handleResponse)
}

async function createUser(user: CreateUserParameter) {
  return await saveupClient.post<IUser>('user', user).then(handleResponse)
}

async function createUserV2(user: CreateUserParameter) {
  return await saveupClient.post<IUser>('v2/user', user).then(handleResponse)
}


async function updateUser(user: UpdateUserParameter) {
  return await saveupClient.put<IUser>('user', user).then(handleResponse)
}

async function updateUserAttribute(userId: number, attributeId: number, value: string) {
  return await saveupClient.put(`user/attribute`, {userId: userId, attributeId: attributeId, value: value}).then(handleResponse)
}

async function deleteUser(id: number) {
  return await saveupClient.delete(`user/${id}`).then(handleResponse)
}

async function updateUserState(userId: number, isDisabled: boolean) {
  return await saveupClient.post(`user/state`, {userId, isDisabled}).then(handleResponse)
}

async function createUserAttribute(userId: number, attributeId: number, value: string) {
  return await saveupClient.post<UserAttribute>(`user/attribute`, {userId: userId, attributeId: attributeId, value: value}).then(handleResponse)
}
async function createUserOwner(userId: number, ownerId: number) {
  return await saveupClient.post<UserOwnerDto>(`user/owner`, {userId: userId, ownerId: ownerId}).then(handleResponse)
}

async function updateUserOwners(userId: number, owners: number[]) {
  return await saveupClient.put(`user/owner`, {userId: userId, ownerIds: owners}).then(handleResponse)
}

async function updateUserPassword(userId: number, password: string) {
  return await saveupClient.post<string>(`user/forceresetpassword`, {userId: userId, newPassword: password}).then(handleResponse)
}

async function updateCurrentUserPassword(username: string, oldPassword: string, newPassword: string, repeatPassword: string) {
  return await saveupClient.post<string>(`user/current/self/resetpassword`, {username, oldPassword, newPassword, repeatPassword}).then(handleResponse)
}

async function sendCurrentUserPasswordReset(username: string) {
  const baseUrl: string = `${process.env.REACT_APP_PROXY_HOST}${process.env.REACT_APP_BASE_URL}`
  const httpHeaders = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  }
  const controller = new AbortController()
  
  const config: AxiosRequestConfig = {
    baseURL: baseUrl,
    headers: httpHeaders,
    timeout: 10000,
    responseType: 'json',
    maxContentLength: 50000,
    maxBodyLength: 50000,
    maxRedirects: 5,
    signal: controller.signal,
  }
  const tmpClient: AxiosInstance = axios.create(config)  
  return await tmpClient.post<string>(`user/current/emailreset`, {email: username}).then(handleResponse)
}

async function createUserInvite(userId: number) {
  return await saveupClient.post<UserSendMailDto>(`user/${userId}/emailinvite`).then(handleResponse)
}

async function createUserResetAndMail(userId: number) {
  return await saveupClient.post<UserSendMailDto>(`user/${userId}/emailreset`).then(handleResponse)
}

async function getPendingUserReset(id: string, token: string) {
  return await saveupClient.get<UserResetDto>(`user/reset/${id}?token=${token}`).then(handleResponse)
}

async function resolvePendingUserReset(guid: string, newPassword: string, resetToken: string) {
  const baseUrl: string = `${process.env.REACT_APP_PROXY_HOST}${process.env.REACT_APP_BASE_URL}`
  const httpHeaders = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  }
  const controller = new AbortController()
  
  const config: AxiosRequestConfig = {
    baseURL: baseUrl,
    headers: httpHeaders,
    timeout: 10000,
    responseType: 'json',
    maxContentLength: 50000,
    maxBodyLength: 50000,
    maxRedirects: 5,
    signal: controller.signal,
  }
  const tmpClient: AxiosInstance = axios.create(config)  
  return await tmpClient.post<UserResetDto>(`user/reset/resolve?token=${resetToken}`, {guid, newPassword}).then(handleResponse)
}

async function resolvePendingUserActivation(guid: string, newPassword: string, activationToken: string) {
  const baseUrl: string = `${process.env.REACT_APP_PROXY_HOST}${process.env.REACT_APP_BASE_URL}`
  const httpHeaders = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  }
  const controller = new AbortController()
  
  const config: AxiosRequestConfig = {
    baseURL: baseUrl,
    headers: httpHeaders,
    timeout: 10000,
    responseType: 'json',
    maxContentLength: 50000,
    maxBodyLength: 50000,
    maxRedirects: 5,
    signal: controller.signal,
  }
  const tmpClient: AxiosInstance = axios.create(config)  
  return await tmpClient.post<UserResetDto>(`user/activate/resolve?token=${activationToken}`, {guid, newPassword}).then(handleResponse)
}


async function createUserReset(userId: number) {
  return await saveupClient.post<UserResetDto>(`user/${userId}/reset`).then(handleResponse)
}

export default userService
