import ConsumptionTable from '../consumption/table/ConsumptionTable'
import ConsumptionView from '../consumption/ConsumptionView'
import {QuestionMarkOutlined, SyncDisabled, UpdateOutlined} from '@mui/icons-material'
import {TabContext, TabList} from '@mui/lab'
import {DesktopDatePicker} from '@mui/x-date-pickers'
import {Box, Button, Card, CardContent, CircularProgress, Container, Divider, FormControlLabel, Grid, Switch, Tab, TextField, Typography} from '@mui/material'
import {IConsumptionNodeDto} from '../../redux/consumption/interfaces'
import {getMeterTypesAtOwner, getNodeConsumption} from '../../redux/owner/ownerActions'
import {IOwnerSummaryProps} from '../../redux/owner/ownerInterfaces'
import {IMeterType} from '../../redux/meter/interfaces'
import {RootState} from '../../redux/rootReducer'
import {graphFromYear, graphToYear, isNullOrWhiteSpace, parseStopDateYearly} from '../../shared/utils/utilities'
import {head} from 'lodash'
import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import OwnerReports from './reports/OwnerReports'
import ConsumptionCostEmissionSelect from '../consumption/ConsumptionCostEmissionSelect'
import {OWNER} from '../../shared/utils/constants'
import ConsumptionOverview from '../consumption/overview/ConsumptionOverview'
import ConsumptionNodeDto from '../../redux/consumption/ConsumptionNodeDto'
import { IUnit } from '../../redux/unit/unitInterfaces'
import { getShouldShowVolume } from '../../redux/configuration/actions'
const OwnerSummary = (props: IOwnerSummaryProps) => {
  const dispatch = useDispatch()
  const [useDegreeDayCorrection, setUseDegreeDayCorrection] = useState(true)
  const [startDate, setStartDate] = useState(new Date(graphFromYear(), 0, 1, 0, 0, 0))
  const [stopDate, setStopDate] = useState(new Date(graphToYear(), 11, 31, 23, 59, 59))
  const [lastLoadedStartDate, setLastLoadedStartDate] = useState(new Date(new Date().getFullYear() - 2, 0, 1, 0, 0, 0))
  const [lastLoadedStopDate, setLastLoadedStopDate] = useState(new Date(new Date().getFullYear(), 11, 31, 23, 59, 59))
  const owner = useSelector((state: RootState) => state.owner.selectedOwner?.owner)
  const [showReportModal, setShowReportModal] = useState<boolean>(false)
  const selectedOffice = useSelector((state: RootState) => state.tree.selectedOffice)
  const selectedMeterType = useSelector((state: RootState) => state.tree.selectedMeterType)
  const meterTypes = useSelector((state: RootState) =>
    state.owner.selectedOwner && state.owner.selectedOwner.meterTypes && selectedMeterType > 0
      ? state.owner.selectedOwner.meterTypes.filter((type) => type.id == selectedMeterType)
      : state.owner.selectedOwner?.meterTypes
  )
  const isLoading = useSelector((state: RootState) => state.owner.isLoading)
  const consumptions = useSelector((state: RootState) => (state.owner.selectedOwner?.consumptions?.find((x) => x.id !== props.ownerId) ? [] : state.owner.selectedOwner?.consumptions))
  const units = useSelector((state: RootState) => state.unit.units)
  const category = useSelector((state: RootState) => state.filter.consumptionCategory)
  const [selectedConsumptionTab, setSelectedConsumptionTab] = useState('')
  const [canEstimate, setCanEstimate] = useState<boolean>(false)
  const [shouldEstimate, setShouldEstimate] = useState<boolean>(true)
  const onConsumptionTabChange = (e: any, obj: any) => {
    setSelectedConsumptionTab(obj.toString())
  }

  useEffect(() => {
    setSelectedConsumptionTab('')
    dispatch(getMeterTypesAtOwner(props.ownerId))
    dispatch(getShouldShowVolume())
  }, [props.ownerId])
  const getConsumptionForTab = () => {
    if (!isNullOrWhiteSpace(selectedConsumptionTab)) {
      const selectedId = parseInt(selectedConsumptionTab)
      let parsedStopDate = parseStopDateYearly(stopDate)
      setLastLoadedStartDate(startDate)
      setLastLoadedStopDate(parsedStopDate)
      const currentMeterType = meterTypes?.find((x: IMeterType) => x.id === selectedId)
      const currentUnit = units?.find((x: IUnit) => x.id === currentMeterType?.unitId)
      const canDegreeDayAdjustCurrentMeterType = currentMeterType?.canDegreeDayAdjust === true
      setCanEstimate(currentMeterType?.isConsumption ?? false)
      setShouldEstimate(currentMeterType?.isConsumption ?? false)
      dispatch(getNodeConsumption(props.ownerId, selectedId, startDate, parsedStopDate, canDegreeDayAdjustCurrentMeterType && useDegreeDayCorrection, selectedOffice, currentMeterType?.isConsumption ?? false, currentUnit, category))
    }
  }

  useEffect(() => {
    if (meterTypes && meterTypes.length > 0 && selectedConsumptionTab === '') {
      setSelectedConsumptionTab(meterTypes[0].id.toString())
    }
  }, [meterTypes])

  useEffect(() => getConsumptionForTab(), [selectedConsumptionTab])

  const update = () => getConsumptionForTab()
  const renderTabLabel = (type: IMeterType) => {
    if (type && units.length > 0) {
      const tabUnit = units.find((x) => x.id === type.unitId)
      return tabUnit ? `${type.name} (${tabUnit.comment})` : ''
    } else if (type) {
      return type.name
    } else {
      return ''
    }
  }
  const renderTab = (type: IMeterType) => {
    return <Tab label={renderTabLabel(type)} value={type.id.toString()} key={type.id} />
  }
  const renderPageLoadingMessages = () => {
    if (props.ownerId !== owner?.id || isLoading) {
      return <CircularProgress sx={{color: '#223354'}} size={256} />
    } else if (props.ownerId === owner?.id && consumptions) {
      return <CircularProgress sx={{color: '#223354'}} size={256} />
    } else if (props.ownerId === owner?.id) {
      return (
        <div>
          <SyncDisabled sx={{color: '#223354', fontSize: 256}} />
          <Typography variant='h5'>Ingen data finns tillgänglig för denna nod.</Typography>
        </div>
      )
    } else {
      return <QuestionMarkOutlined sx={{color: '#223354', fontSize: '256px'}} />
    }
  }

  const renderGraphs = (consumption: ConsumptionNodeDto[] | undefined, typeIdString: string, deviations: {[key: string]: number} | null, startDate: Date, stopDate: Date, caetegory: string) => {
    if (isNullOrWhiteSpace(typeIdString)) {
      return (
        <div>
          <QuestionMarkOutlined sx={{color: '#223354', fontSize: 128}} />
          <Typography variant='h5'>Ingen förbrukningsflik vald.</Typography>
        </div>
      )
    }

    if (consumption) {
      const typeId = parseInt(typeIdString)
      const type = head(meterTypes?.filter((x) => x.id === typeId))
      if (!type)
        return (
          <div>
            <Typography variant='h5'>Inga mätartyper hittades för denna fastighetsägare!</Typography>
          </div>
        )

      return (
        <div>
          <ConsumptionView data={consumption} meterType={type} deviations={deviations} startDate={startDate} stopDate={stopDate} category={category} nodeType={OWNER} />
          <Divider sx={{'marginTop': '20px', 'marginBottom': '20px'}} />
          <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={5}>
            <Grid item xs={12}>
              <OwnerReports id={props.ownerId} name={owner ? owner.name : ''} show={showReportModal} onFinished={() => setShowReportModal(false)} />
            </Grid>
          </Grid>
          <Divider sx={{'marginTop': '20px', 'marginBottom': '20px'}} />
          <ConsumptionTable data={consumption} meterType={type} deviations={deviations} startDate={startDate} stopDate={stopDate} category={category} shouldEstimate={shouldEstimate} />
        </div>
      )
    } else {
      return <div>Det gick inte att ladda in vald flik. Vänligen försök igen.</div>
    }
  }
  if (props.ownerId !== owner?.id || isLoading || selectedConsumptionTab === '' || !meterTypes?.some((x) => x.id.toString() === selectedConsumptionTab)) {
    return (
      <Box sx={{'margin': '10px'}}>
        <Grid container direction='row' alignItems='stretch' spacing={1}>
          <Grid item xs={12}>
            <Box display='flex' justifyContent='center' alignItems='center' minHeight='600px'>
              {renderPageLoadingMessages()}
            </Box>
          </Grid>
        </Grid>
      </Box>
    )
  } else {
    const meterType = meterTypes.find((x: IMeterType) => x.id == parseInt(selectedConsumptionTab))
    const canDegreeDayAdjustMeterType = meterType?.canDegreeDayAdjust === true
    return (
      <Container maxWidth='lg'>
        <Card>
          <CardContent>
            <TabContext value={selectedConsumptionTab}>
              <Box>
                <TabList onChange={onConsumptionTabChange} scrollButtons='auto' variant='scrollable'>
                  {meterTypes?.map((x) => renderTab(x))}
                </TabList>
              </Box>
              <Box sx={{'marginTop': '10px'}}>
                <Grid>
                  <Grid container direction='row' alignItems='stretch' spacing={1}>
                    <Grid item xs={2}>
                      <DesktopDatePicker
                        views={['year']}
                        label='Från'
                        value={startDate}
                        onChange={(date: Date | null) => {
                          if (date) setStartDate(date)
                        }}
                        renderInput={(params: any) => <TextField {...params} />}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <DesktopDatePicker
                        views={['year']}
                        label='Till'
                        value={stopDate}
                        onChange={(date: Date | null) => {
                          if (date) setStopDate(date)
                        }}
                        renderInput={(params: any) => <TextField {...params} />}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <ConsumptionCostEmissionSelect meterType={meterType} />
                    </Grid>
                    {canDegreeDayAdjustMeterType ? (
                      <Grid item xs={3}>
                        <FormControlLabel
                          control={<Switch checked={useDegreeDayCorrection} onChange={() => setUseDegreeDayCorrection(useDegreeDayCorrection ? false : true)} title='Använd graddagsjustering' />}
                          label='Använd graddagsjustering'
                        />
                      </Grid>
                    ) : (
                      ''
                    )}
                    {canEstimate ? (
                      <Grid item xs={3}>
                        <FormControlLabel control={<Switch checked={shouldEstimate} onChange={() => setShouldEstimate((estimate) => !estimate)} title='Visa estimat' />} label='Visa estimat' />
                      </Grid>
                    ) : (
                      ''
                    )}
                    <Grid item xs={2} alignSelf='right'>
                      <Button sx={{mt: {xs: 2, md: 0}}} variant='contained' color='primary' startIcon={<UpdateOutlined fontSize='small' />} onClick={update}>
                        Uppdatera
                      </Button>
                    </Grid>
                  </Grid>
                  {shouldEstimate && meterType && units?.length > 0 && consumptions ? <Grid container direction='row' alignItems='stretch' spacing={1}>
                    <Grid item xs={12}>
                        <ConsumptionOverview data={consumptions} meterType={meterType} startDate={lastLoadedStartDate} stopDate={lastLoadedStopDate} category={category} unit={units.find(x => x.id == meterType.unitId)}  />
                    </Grid>
                  </Grid> : ''}
                  <Grid container direction='row' alignItems='stretch' spacing={1}>
                    <Grid item xs={12}>
                      {renderGraphs(consumptions, selectedConsumptionTab, null, lastLoadedStartDate, lastLoadedStopDate, category)}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </TabContext>
          </CardContent>
        </Card>
      </Container>
    )
  }
}
export default OwnerSummary
